import "../../../js/import/jquery.maskedinput";
import "../../../js/import/jquery.validate";
import "../../../js/import/messages_ru";

$(document).ready(function(){
    function valideForms(form) {
        $(form).validate({
            rules: {
                name: {
                required: true,
                minlength: 2
                },
                phone: "required",
                email: {
                required: true,
                email: true
                }
            },
            messages: {
                name: {
                minlength: jQuery.validator.format("Введите не менее {0} символов!")
                }
            }
        });
    };

    valideForms('#access-form');

    $('input[name=phone]').mask("+375 (99) 999-99-99")

    $('[data-modal=access]').on('click', function() {
        $('.overlay, #access').fadeIn('slow');
    });

    $('.modal__close').on('click', function() {
        $('.overlay, #access, #thanks').fadeOut('slow');
        $('form').trigger('reset');
    });


    $('#access-form').submit(function(e) {
        e.preventDefault();
        if($(this).find("input").val() !='' && $(this).find("input").val() != null)
        {
        $.ajax({
            type: "POST",
            url: "mailer/smart.php",
            data: $(this).serialize()
        }).done(function() {
            $(this).find("input").val("");
            $('.overlay, #thanks').fadeIn('slow');
      
            $('form').trigger('reset')
          });
          alert("Данные отправлены");
        }
        else {
          alert("Данные не отправлены")
        }
        return false;
    });

});